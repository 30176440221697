import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, cardUp, fadeInLeft, fadeInRight } from '../../animations/variants';
import bike from '../../assets/images/bike-logo.png';
import nursery from '../../assets/images/nursery-logo.png';
import { ScContainer } from '../../components/container/styled';
import { IImageTextCardItem, ImageTextCard } from '../../components/ImageTextCard';
import useContentAnimation from '../../hooks/use-content-animation';
import useWindowDimensions from '../../hooks/use-window-width';

import { ScSchemasWrapper } from './styled';

const items: IImageTextCardItem[] = [
	{
		title: 'Bike',
		text: 'We think you deserve a fair deal, too. That’s why we charge significantly less commission than other cycle to work providers. Our flexi-voucher also means customers don’t have to use their voucher all at once. With Gogeta, they can spend it on whatever they like, whenever they want.',
		image: bike,
	},
	{
		title: 'Nursery',
		text: 'It’s not just parents who will save money with Gogeta – you’ll also receive a funding per month, per child when they sign up. Signing up is easy, so you can focus on doing what you do best.',
		image: nursery,
	},
];

export const Schemas = () => {
	const { ref, controls } = useContentAnimation();
	const { width } = useWindowDimensions();
	const theme = useTheme();

	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScSchemasWrapper ref={ref}>
					{items.map((item, index) => (
						<MotionDiv
							key={item.title}
							variants={width >= 1220 ? cardUp : index % 2 === 0 ? fadeInLeft : fadeInRight}
						>
							<ImageTextCard
								item={item}
								background={theme.colors.dough}
								color={theme.colors.darkText}
								isLargeImage
							/>
						</MotionDiv>
					))}
				</ScSchemasWrapper>
			</motion.section>
		</ScContainer>
	);
};
