import styled from 'styled-components';

export const ScProviderPathwayHeroWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 55px;
	box-sizing: border-box;
	gap: 45px;
	position: relative;

	.image {
		z-index: 10;
	}

	img {
		width: 290px;
		height: auto;
		margin-top: -65px;
		z-index: 5;
		transform: scaleX(-1);
	}
	@media (min-height: 600px) and (min-width: 390px) {
		height: calc(100svh - 65px);
	}
	justify-content: space-between;
	@media (min-width: 768px) {
		height: 100%;
		@media (min-height: 1000px) {
			height: calc(100svh - 95px);
		}
	}
	@media (min-width: 768px) {
		img {
			width: 80%;
		}
	}

	@media (min-width: 1024px) and (min-height: 600px) {
		height: calc(100vh - 95px);
	}
	@media (min-width: 1220px) and (min-height: 600px) {
		height: calc(100vh - 120px);
	}
	@media (min-width: 1024px) {
		.image {
			position: absolute;
			right: 0;
			bottom: 30px;
		}
		img {
			width: 400px;
		}
	}
	@media (min-width: 1220px) {
		padding: 30px 0;
		gap: 20px;
		.image {
			bottom: 100px;
		}

		img {
			margin: 0;
			width: 600px;
		}
	}

	@media (min-width: 1920px) {
		img {
			width: 765px;
		}
	}

	.buttons {
		@media (min-width: 768px) {
			z-index: 10;
			margin-top: auto;
		}
	}
	@media (min-width: 1220px) and (max-height: 900px) {
		img {
			width: 350px;
		}
		.image {
			bottom: 50px;
		}
	}
	@media (min-width: 1220px) and (max-width: 1919px) and (max-height: 800px) {
		.hero-title {
			font: ${({ theme }) => theme.fonts.largeHeading.smallDesktop} !important;
		}
	}
`;

export const ScProviderPathwayHeroButtons = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;

	button {
		width: 100px;
	}

	@media (min-width: 768px) {
		gap: 24px;
		button {
			width: 162px;
		}
	}
	@media (min-width: 1220px) {
		margin-bottom: 50px;
	}
`;

export const ScSchemasWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 8px;
	> div {
		height: 100%;
	}
	p {
		width: auto;
	}

	@media (min-width: 1220px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 20px;

		> div {
			> div {
				padding: 90px 85px 42px 85px;
			}
		}
	}

	@media (min-width: 1440px) {
		gap: 20px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 120px;

		> div {
			> div {
				padding: 90px 20px 60px 20px;
			}
		}
	}

	@media (min-width: 1920px) {
		> div {
			> div {
				padding: 90px 110px 42px 110px;
			}
		}
	}
`;
