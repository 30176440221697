import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInUp } from '../../animations/variants';
import image from '../../assets/images/bread-home.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScLargeTitle } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScProviderPathwayHeroButtons, ScProviderPathwayHeroWrapper } from './styled';

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.toasted}>
				<ScContainer>
					<ScProviderPathwayHeroWrapper>
						<MotionDiv variants={fadeInUp}>
							<ScLargeTitle className="hero-title" centered color={theme.colors.wheat}>
								same dough
								<br />
								more bread
							</ScLargeTitle>
						</MotionDiv>
						<MotionDiv className="image" variants={fadeInLeft}>
							<img src={image} alt="bread" />
						</MotionDiv>
						<MotionDiv variants={fadeInUp} className="buttons">
							<ScProviderPathwayHeroButtons>
								<Link to="/provider-cycling/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										Bike
									</CustomButton>
								</Link>
								<Link to="/provider-nursery/">
									<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
										Nursery
									</CustomButton>
								</Link>
							</ScProviderPathwayHeroButtons>
						</MotionDiv>
					</ScProviderPathwayHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
